/**
 * Events hot counter
 * When a user clicks a fire icon, it adds one to the hot counter of that post
 * User is prevented from add more than one to the counter of a post
 * ===========================================================
 */

// Delete "already clicked" history
// localStorage.removeItem('edgeHotEvents');

function eventsHotCounter(nonce) {
    const counters = jQuery('.event-hot-counter');

    // If there is no edgeHotEvents array, create a blank one
    if (localStorage.getItem('edgeHotEvents') === null) {
        localStorage.setItem('edgeHotEvents', '[]');
    }

    let ajax_options = {
        action: 'event_hot_counter',
        nonce: nonce,
        ajaxurl: '/wp-admin/admin-ajax.php',
        post_id: '',
        new_hot_count: ''
    };

    counters.on( 'click', function(e) {
        e.preventDefault();
        const edgeHotEvents = JSON.parse(localStorage.getItem('edgeHotEvents'));
        const postID = this.dataset.postid;

        // If user is logged in, exit with error (it doesn't work)
        if (document.body.classList.contains( 'logged-in' )) {
            alert('Hot counter does not work for logged in users.');
            return false;
        }

        // If the user has not already clicked on this post
        if (!edgeHotEvents.includes(postID)) {

            // mark as clicked for this user
            edgeHotEvents.push(postID);
            localStorage.setItem('edgeHotEvents', JSON.stringify(edgeHotEvents));

            // add plus one to the counter on the page
            const hotCount = this.querySelector('.hot-count');
            hotCount.textContent = parseInt(hotCount.textContent) + 1;

            // add plus one to the counter in the tooltip
            const hotCountToolTip = this.querySelector('.tool-tip-hot-count');
            hotCountToolTip.textContent = parseInt(hotCountToolTip.textContent) + 1;

            // add plus one to the count in the database
            ajax_options.post_id = postID;
            jQuery.post( ajax_options.ajaxurl, ajax_options, function() {});
        }
        return false;
    });

    const editLinks = jQuery('.edit-hot-count');
    editLinks.on( 'click', function(e) {
        e.preventDefault();
        const postID = this.dataset.postid;
        const editInput = document.querySelector(`.edit-hot-count-input[data-postid="${postID}"]`);
        const editSave = document.querySelector(`.edit-hot-count-save[data-postid="${postID}"]`);
        editInput.classList.remove('hide');
        editSave.classList.remove('hide');
        this.classList.add('hide');
        const hotCount = document.querySelector(`.event-hot-counter[data-postid="${postID}"]`);
        hotCount.classList.add('hide');
        return false;
    });
    const editInput = jQuery('.edit-hot-count-input');
    editInput.on( 'click', function(e) {
        e.preventDefault();
        return false;
    });

    const editSaveLinks = jQuery('.edit-hot-count-save');
    editSaveLinks.on( 'click', function(e) {
        e.stopPropagation();
        const postID = this.dataset.postid;
        const editInput = document.querySelector(`.edit-hot-count-input[data-postid="${postID}"]`);

        // change the count in the database
        ajax_options.post_id = postID;
        ajax_options.new_hot_count = editInput.value;
        jQuery.post( ajax_options.ajaxurl, ajax_options, function() {});

        // change the counter on the page
        const hotCount = document.querySelector(`.event-hot-counter[data-postid="${postID}"] .hot-count`);
        hotCount.textContent = editInput.value;

        // change the counter in the tooltip
        const hotCountToolTip = document.querySelector(`.event-hot-counter[data-postid="${postID}"] .tool-tip-hot-count`);
        hotCountToolTip.textContent = editInput.value;

        // display saved message
        const editSaved = document.querySelector(`.edit-hot-count-saved[data-postid="${postID}"]`);
        editSaved.classList.remove('hide');

        const editLink = document.querySelector(`.edit-hot-count[data-postid="${postID}"]`);
        editLink.classList.remove('hide');
        const hotCounter = document.querySelector(`.event-hot-counter[data-postid="${postID}"]`);
        hotCounter.classList.remove('hide');
        editInput.classList.add('hide');
        this.classList.add('hide');

        setTimeout(function() {
            editSaved.classList.add('hide');
        }, 3000);

        return false;
    });
}
